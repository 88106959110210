import HeadingTagsPopover from "../../pages/AR-Dashboard/popover-tag";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import HeadingTypePopover from "../../pages/AR-Dashboard/doctype";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
    Box,
    IconButton,
    TextField,
    Button,
    Typography,
    Stack,
    InputAdornment,
    CircularProgress,
    Collapse,
    Divider,
    Chip
} from "@mui/material";
import GradientIcon from "../../pages/AR-Dashboard/grad-icon";
import SearchIcon from "@mui/icons-material/Search";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import RefreshIcon from "@mui/icons-material/Refresh";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import React, {useEffect, useRef, useState} from "react";
import api from "../../API";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import {useNavigate, useLocation} from 'react-router-dom';
import LoadingButton from "../Buttons/LoadingButton";
import {useTranslation} from 'react-i18next';



const FilterContent = (FilterArgs) => {
    const navigate = useNavigate();
    const location = useLocation();
    const defaultDocType = (location.state && location.state.defaultDocType) ? location.state.defaultDocType : {};
    const [selectedDocTypes, setSelectedDocTypes] = useState(
        Object.keys(defaultDocType).length ? [defaultDocType] : FilterArgs.params.docTypes
    );
    useEffect(() => {
        if (defaultDocType) {
            navigate(location.pathname, {replace: true, state: {}});
        }
    }, [navigate, location.pathname]);
    const [tags, setTags] = useState([]);
    const [docTypeSearch, setDocTypeSearch] = useState(FilterArgs.params.docTypes);
    const [tagsSearch, setTagsSearch] = useState(FilterArgs.params.tadIDs);
    const [selectedTags, setSelectedTags] = useState(FilterArgs.params.tadIDs);
    const [contentSearch, setContentSearch] = useState(FilterArgs.params.contentSearch);
    const [searchContent, setSearchContent] = useState(FilterArgs.params.contentSearch);
    const initialTagCollapseOpen = FilterArgs.params.docTypes.length > 0 ||
        FilterArgs.params.tadIDs.length > 0 ||
        FilterArgs.params.contentSearch !== null;
    const [tagCollapseOpen, setTagCollapseOpen] = useState(initialTagCollapseOpen);
    // const [open, setOpen] = useState(FilterArgs.params.aiMessage !== null);
    const [docTypes, setDocTypes] = useState([]);
    const [message, setMessage] = useState(FilterArgs.params.aiMessage);
    const [messages, setMessages] = useState([]);
    const [isChatVisible, setIsChatVisible] = useState(true);
    const chatContentRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [tagSearch, setTagSearch] = useState(null);
    const {t} = useTranslation();


    const tagSearchChange = (searchValue) => {
        // console.warn("searchValue", searchValue);
        setTagSearch(searchValue);
    }

    useEffect(() => {
        const fetchTypes = async () => {
            try {
                const response = await api.get('data/doc_type');
                if (response.status !== 200) {
                    console.error('Files loading error', response.status);
                    return false;
                }
                const responseData = await response.data;
                setDocTypes(responseData.results);
                return true;
            } catch (error) {
                console.error('Error fetching documents:', error);
            }
            return false;
        };
        fetchTypes();
    }, []);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const params = {};
                params.limit = 10;
                if (tagSearch) {
                    params.tag_name = tagSearch;
                }
                const response = await api.get('data/tags', {params});
                if (response.status !== 200) {
                    console.error('Files loading error', response.status);
                    return false;
                }
                const responseData = await response.data;
                setTags(responseData.results)
                return true;
            } catch (error) {
                console.error('Error fetching documents:', error);
            }
            return false;
        };
        fetchTags();
    }, [tagSearch]);


    const Summarize = () => {
        const newMessages = [...messages, {from: 'user', text: 'Summarizing...'}];
        setMessages(newMessages);
        setTimeout(() => {
            const data = {
                inputMessage: 'Summarize this document',
                FileName: document.document_id,
            };

            api.post('ai/get_summary', data)
                .then(response => {
                    const newMessages = [
                        ...messages,
                        {from: 'user', text: "Summarizing..."},
                        {from: 'backend', text: response.data.response}
                    ];
                    setMessages(newMessages);
                    FilterArgs.params.aiMessage = '';
                    setMessage('')
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }, 0);
    };

    useEffect(() => {
        const typeMap = new Map();
        selectedDocTypes.forEach(type_ => {
            typeMap.set(type_.document_type, type_);
        });
        const uniqueTypes = Array.from(typeMap.values());
        setDocTypeSearch(uniqueTypes);
        if (uniqueTypes.length > 0) {
            setTagCollapseOpen(true);
        }
    }, [selectedDocTypes]);


    useEffect(() => {
        if (chatContentRef.current) {
            chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
        }
    }, [messages]);

    const handleContentSearchClick = () => {
        setContentSearch(searchContent);
    };
    const handleEnterKeySearch = (event) => {
        if (event.key === 'Enter') {
            handleContentSearchClick();
        }
    };


    useEffect(() => {
        FilterArgs.createParams(message, tagsSearch, docTypeSearch, contentSearch);
    }, [tagsSearch, docTypeSearch, contentSearch]);


    useEffect(() => {
        setTagsSearch(selectedTags);
    }, [selectedTags]);


    const handleTagsChange = (tags) => {
        setSelectedTags(tags.selectedTags);
        // handleTagCollapse();
    };

    const handleDeleteTag = (tagToDelete) => {
        const updatedTags = tagsSearch ? tagsSearch.filter(tag => tag.tag_id !== tagToDelete.tag_id) : [];
        setSelectedTags(updatedTags);
        // handleTagCollapse();
    };

    const handleContentSearchChange = (event) => {
        setSearchContent(event.target.value);
    }

    const handleDocTypesChange = (docTypes) => {
        setSelectedDocTypes(docTypes.selectedTypes);
        // handleTagCollapse();
    };
    const handleDeleteType = (typeToDelete) => {
        const updatedType = docTypeSearch ? docTypeSearch.filter(type_ => type_.document_type !== typeToDelete.document_type) : [];
        setSelectedDocTypes(updatedType);
        // handleTagCollapse();
    };

    const handleRefresh = () => {
        setRefreshLoading(true);
        api.post('data/clear_cache').then(r => {
            FilterArgs.clearDocIds();
            FilterArgs.programmaticUpdate.current = true;
            setSelectedTags([]);
            FilterArgs.programmaticUpdate.current = true;
            setContentSearch('');
            FilterArgs.programmaticUpdate.current = true;
            setSearchContent('');
            FilterArgs.programmaticUpdate.current = true;
            setSelectedDocTypes([]);
            FilterArgs.programmaticUpdate.current = true;
            setMessage('');
            FilterArgs.programmaticUpdate.current = false;
            FilterArgs.params.aiMessage = '';
            FilterArgs.updateSelectedItems([]);
            FilterArgs.updateSelectedOperation(null);
            FilterArgs.updatePreviousFolderID(null);
            // FilterArgs.handleRefreshOuter();
            // console.log('tagsSearch after refresh:', tagsSearch);
        });
        setRefreshLoading(false);
    };
    const handleUploadClick = () => {
        navigate('/dropbox'); // Navigate to Dashboard page
    };
    return (
        <>
            <Box sx={{
                display: 'flex',
                gap: 3,
                width: /*open ? 'calc(100% - 25vw)' :*/ '100%',
                transition: 'width 0.3s ease-in-out',
                borderRadius: '8px',
                paddingBlock: '16px',
                alignItems: 'center',
            }}
            >
                <Stack
                    direction={{ xs: 'column', sm: 'column', md: 'column', lg:'column', xl: FilterArgs.openDrawerChat? 'column': 'row' }}
                    spacing={3}
                    alignItems="center"
                    flexGrow={1}
                >
                    <Box sx={{display:'flex', gap:3, alignItems:"center", flexGrow: 1}}>
                        <HeadingTagsPopover tags={tags} header={t('tags')} header_icon={LocalOfferIcon}
                                            onChangeFunction={handleTagsChange} selectedTags={selectedTags}
                                            tagSearchChange={tagSearchChange}/>
                        <HeadingTypePopover types={docTypes} header={t('document_type')}
                                            header_icon={ContentPasteIcon} selectedTypes={selectedDocTypes}
                                            onChangeFunction={handleDocTypesChange}/>
                        <TextField
                            label={t('name/content_search')}
                            variant="outlined"
                            size="small"
                            color='secondary'
                            fullWidth
                            value={searchContent ?? ''}
                            onChange={handleContentSearchChange}
                            onKeyPress={handleEnterKeySearch}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton size="small" onClick={handleContentSearchClick}>
                                            <GradientIcon IconComponent={SearchIcon}/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                flex: 1,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '4px',
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#ef798a',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#ef798a',
                                    },
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#ccc',
                                },
                                '& .MuiOutlinedInput-input': {
                                    padding: '10px',
                                },
                            }}
                        />
                    </Box>
                    <Box sx={{display:'flex', gap:3, flexGrow: 1}}>
                        <Button variant="outlined" size="small" onClick={FilterArgs.handleDrawerChat}
                                color="secondary"
                                startIcon={<GradientIcon IconComponent={AutoAwesomeIcon}/>}
                        >
                            <Typography sx={{
                                fontWeight: 600,
                                fontSize: '1rem',
                                lineHeight: 1.2,
                                letterSpacing: '0.01em',
                                background: 'linear-gradient(to right, #ef798a, #674f87)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                            }}>
                                {t('ai')}
                            </Typography>
                        </Button>
                        <LoadingButton
                            isLoading={refreshLoading}
                            onClick={handleRefresh}
                            circleSize={20}
                            circleColor={{color: '#220303'}}
                            startIcon={<GradientIcon IconComponent={RefreshIcon}/>}
                            variant="outlined"
                            size="small"
                            color="secondary"
                        />
                        {!FilterArgs.isFolderType && (
                            <Button variant="outlined" size="small" onClick={FilterArgs.handleFolderType} color="secondary"
                                    startIcon={<GradientIcon IconComponent={FolderOpenIcon}/>}>
                                {t('folder_view')}
                            </Button>
                        )}

                        {FilterArgs.isFolderType && (
                            <Button variant="outlined" size="small" onClick={() => FilterArgs.handleItemView()}
                                    color="secondary"
                                    startIcon={<GradientIcon IconComponent={ViewCompactIcon}/>}>
                                {t('item_view')}
                            </Button>
                        )}
                        <Button variant="outlined" size="small" onClick={handleUploadClick} color="secondary"
                                startIcon={<GradientIcon IconComponent={FileUploadIcon}/>}>
                            {t('upload')}
                        </Button>
                    </Box>
                </Stack>
            </Box>
            <Collapse in={tagCollapseOpen}>
                <Box>
                    <Stack direction="row" spacing={1}
                           sx={{
                               paddingBottom: 1,
                               overflowX: 'auto',
                               width: '94%',
                               flexDirection: 'row-reverse',  // Align items right to left
                               '&::-webkit-scrollbar': {      // Style for scroll bar thickness
                                   height: '4px',             // Adjust the scroll bar thickness
                               },
                               '&::-webkit-scrollbar-thumb': {  // Style for scroll bar thumb
                                   backgroundColor: '#888',     // Customize thumb color
                                   borderRadius: '4px',         // Make scroll bar thumb rounded
                               },
                               '&::-webkit-scrollbar-thumb:hover': {  // Hover effect on scroll bar thumb
                                   backgroundColor: '#555',
                               }
                           }}>
                        {tagsSearch.map((tag) => (
                            <Chip key={tag.tag_id} label={tag.tag_name} variant="outlined" color='secondary'
                                  onDelete={() => handleDeleteTag(tag)}/>
                        ))}
                        {docTypeSearch.map((type_) => (
                            <Chip key={type_.document_type} label={type_.document_type} variant="outlined"
                                  color='secondary'
                                  onDelete={() => handleDeleteType(type_)}/>
                        ))}
                    </Stack>
                </Box>
            </Collapse>
        </>
    );
};

export default FilterContent;
