import React, {useEffect, useState, useRef, useCallback} from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import {
    Box,
    Button,
    Tooltip,
    styled,
    tooltipClasses,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import GridWrapper from '../common/GridWrapper/GridWrapper';
import DocumentThumbnail from "../../components/Document/DocumentThumbnail";
import {useTranslation} from 'react-i18next';
import ContextMenu from '../ContextMenu/ContextMenu';
import FolderRender from "./FolderRender";
import api from "../../API";
import CustomSnackbar from "../SnackBar/Snackbar";

const CustomTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#d8d8d8',
        color: '#674f87',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));


const FolderView = ({
                        docs,
                        customFields,
                        rootFolderId,
                        handleItem,
                        downloadPdf,
                        downloadLoading,
                        handleDeleteOpen,
                        deleteOpen,
                        handleDeleteClose,
                        deleteDocument,
                        deleteLoading,
                        handleParentChange,
                        handleCheckboxChange,
                        selectedDocuments,
                        handleDocsReload,
                        folderNavigateTo,
                        folderPathSlicer,
                        folderPath,
                        selectedItems,
                        selectedOperation,
                        previousFolderID,
                        updateSelectedItems,
                        updateSelectedOperation,
                        updatePreviousFolderID,
                    }) => {
    const {t} = useTranslation();
    const [newData, setNewData] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [renameDialogOpen, setRenameDialogOpen] = useState(false);
    const [rename, setRename] = useState('');
    const [createFolderDialogOpen, setCreateFolderDialogOpen] = useState(false);
    const [newFolder, setNewFolder] = useState(null);
    const [snackbarOpen, setSnackBarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [createLoading, setCreateLoading] = useState(false);
    console.warn(selectedOperation);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarOpen(false);
    };
    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackBarOpen(true);
    };
    const handleCreateFolderClick = () => {
        setCreateFolderDialogOpen(true);
    };

    const handleCreateFolderSave = async () => {
        setCreateLoading(true);
        try {
            const params = [{
                operation: 'create',
                document_id: null,
                folder_id: null,
                folder_name: newFolder,
                parent_folder_id: folderPath.length > 0 ? folderPath[folderPath.length - 1].folderID : null,
                previous_folder_id: null,
            }]
            const response = await api.post(`data/folder_mapping`, {params});
            if (response.status === 200) {
                setCreateFolderDialogOpen(false);
                setNewFolder(null);
                showSnackbar(t('folder_created_successfully'));
                handleDocsReload();
                updatePreviousFolderID(null);
                updateSelectedOperation(null);
                updateSelectedItems([]);
                renderContents(docs);
            } else {
                showSnackbar(t('folder_create_error'));
            }
        } catch (err) {
            console.error(err);
        }
        setCreateLoading(false)
    };

    const handleDelete = async () => {
        setCreateLoading(true);
        try {
            const params = selectedItems.map(item => ({
                operation: 'delete',
                document_id: item.document_id,
                folder_id: item.folderID,
                folder_name: null,
                parent_folder_id: null,
                previous_folder_id: null,
            }));
            const response = await api.post(`data/folder_mapping`, {params});
            if (response.status === 200) {
                setCreateFolderDialogOpen(false);
                setNewFolder(null);
                showSnackbar(t(`folder_deleted_successfully`));
                handleDocsReload();
                updatePreviousFolderID(null);
                updateSelectedOperation(null);
                updateSelectedItems([]);
                renderContents(docs);
            } else {
                showSnackbar(t(`folder_delete_error`));
                console.error('Failed to delete document');
            }
        } catch (err) {
            console.error(err);
        }
        setCreateLoading(false)
    };

    const handlePaste = async () => {
        setCreateLoading(true);
        try {
            const params = selectedItems.map(item => ({
                operation: selectedOperation,
                document_id: item.documentID,
                folder_id: item.folderID,
                folder_name: null,
                parent_folder_id: folderPath.length > 0 ? folderPath[folderPath.length - 1].folderID : null,
                previous_folder_id: previousFolderID,
            }));
            console.warn(params);
            const response = await api.post(`data/folder_mapping`, {params});
            if (response.status === 200) {
                showSnackbar(t(`folder_${params.operation}_successfully`));
                updatePreviousFolderID(null);
                updateSelectedOperation(null);
                updateSelectedItems([]);
                handleDocsReload();
                renderContents(docs);
            } else {
                console.error('Failed to delete document');
            }
        } catch (err) {
            console.error(err);
        }
        setCreateLoading(false)
    };


    const [isDragging, setIsDragging] = useState(false);
    const [dragStartIndex, setDragStartIndex] = useState(null);

    const handleRightClick = (event, item) => {
        event.preventDefault();

        setAnchorEl(event.currentTarget);

        if (!event.ctrlKey) {
            updateSelectedItems([item]);
        } else {
            updateSelectedItems(item, true);
        }
        setIsDragging(false);
    };

    const handleMouseDown = (event, index) => {
        event.preventDefault();
        setIsDragging(true);
        setDragStartIndex(index);
    };
    const componentRef = useRef(null);
    const handleClickOutside = (event) => {
        if (componentRef.current && !componentRef.current.contains(event.target)) {
            updateSelectedItems([]);  // Clear the selected items
            setIsDragging(false);   // Reset the dragging state
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleMouseUp = () => {
        setIsDragging(false);
        setDragStartIndex(null);
    };

    const handleMouseEnter = (e, index, allItems) => {
        if (isDragging && dragStartIndex !== null) {
            const range = [dragStartIndex, index].sort((a, b) => a - b);
            updateSelectedItems(null, false, allItems, range);
        }
    };


    /*
        const handleRightClick = (event, item) => {
            event.preventDefault();
            setSelectedItems((prevData) => [...prevData, item]);
            console.log(selectedItems);
            setAnchorEl(event.currentTarget);
        };
    */

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuAction = (action) => {
        switch (action) {
            case 'cut':
                updateSelectedOperation('cut');
                updatePreviousFolderID(folderPath.length > 0 ? folderPath[folderPath.length - 1].folderID : null);
                // console.log('Cut', selectedItems);
                break;
            case 'create':
                handleCreateFolderClick();
                break;
            case 'copy':
                updateSelectedOperation('copy');
                break;
            case 'paste':
                handlePaste();
                console.log('Pasted');
                break;
            case 'sendTo':
                updateSelectedOperation('copy');
                // conParentsole.log('Send To', selectedItems);
                break;
            case 'rename':
                setRename(selectedItems);
                setRenameDialogOpen(true);
                break;
            case 'delete':
                handleDelete();
                // console.log('Delete', selectedItems);
                break;
            default:
                break;
        }
    };

    /*    const handlePaste = () => {
            if (newData.operation === "copy") {
                const pastedItem = {
                    ...newData,
                    folder_id: selectedItems.folderID,
                    folder_name: selectedItems.folderName
                };
                docs.push(pastedItem);
                console.log('Pasted item', pastedItem);
                setNewData((prevData) => ({...prevData, operation: ''}));
            } else {
                console.log('Nothing to paste');
            }
        }*/

    const handleRenameClose = () => {
        setRenameDialogOpen(false);
    };

    const handleRenameSave = () => {
        setNewData((prevData) => ({
            ...prevData,
            subfolder_name: rename,
        }));
        handleRenameClose();
    };

    const changeParent = (folderID) => {
        handleParentChange(folderID);
        folderPathSlicer(folderID);
    }

    const renderContents = (docs) => {
        return (
            docs.length > 0 ?
                (<FolderRender
                    docs={docs}
                    customFields={customFields}
                    rootFolderId={rootFolderId}
                    folderNavigateTo={folderNavigateTo}
                    handleItem={handleItem}
                    downloadPdf={downloadPdf}
                    downloadLoading={downloadLoading}
                    handleDeleteOpen={handleDeleteOpen}
                    deleteOpen={deleteOpen}
                    handleDeleteClose={handleDeleteClose}
                    deleteDocument={deleteDocument}
                    deleteLoading={deleteLoading}
                    handleCheckboxChange={handleCheckboxChange}
                    selectedDocuments={selectedDocuments}
                    selectedItems={selectedItems}
                    handleRightClick={handleRightClick}
                    handleMouseUp={handleMouseUp}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseDown={handleMouseDown}
                />) : ("")
        );
    };

    const handleBreadCrumb = (event) => {
        event.preventDefault();
    };

    const truncateName = useCallback((name, length) => {
        return name.length > length ? `${name.substring(0, length)}...` : name;
    }, []);
    return (
        <GridWrapper sx={{paddingTop: '10px'}}>
            <Box
                sx={{
                    marginTop: 2,
                    marginBottom: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                    width: '80%',
                    backgroselectedOperationund: '#ffffff',
                }}
            >
                {/*{folderPath.length > 0 && (
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => changeParent()}
                        color="secondary"
                        startIcon={<ArrowLeftIcon/>}
                    >
                        {t('back')}
                    </Button>
                )}*/}

                {folderPath.length > 0 && (
                    <Box onClick={handleBreadCrumb}>
                        <Breadcrumbs aria-label="breadcrumb" sx={{fontWeight: 'bold', color: '#ef798a'}}>
                            <Link
                                underline="hover"
                                color="inherit"
                                onClick={() => changeParent(0)}
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#674f87',
                                    '&:hover': {color: '#ef798a'},
                                    cursor: 'pointer',
                                    margin: '0 8px',
                                }}
                            >
                                {t('documents')}
                            </Link>
                            {folderPath.map((folder) => (
                                <CustomTooltip title={folder.folderName} key={folder.folderID}>
                                    <Link
                                        underline="hover"
                                        color="inherit"
                                        onClick={() => changeParent(folder.folderID)}
                                        sx={{
                                            fontWeight: 'bold',
                                            color: '#674f87',
                                            '&:hover': {color: '#ef798a'},
                                            cursor: 'pointer',
                                            margin: '0 8px',
                                        }}
                                    >
                                        {truncateName(folder.folderName, 12)}
                                    </Link>
                                </CustomTooltip>
                            ))}
                        </Breadcrumbs>
                    </Box>
                )}
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    onClick={handleCreateFolderClick}
                >
                    Create Folder
                </Button>
                {(selectedOperation === 'copy' || selectedOperation === 'cut') && (
                    <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={handlePaste}
                    >
                        Paste
                    </Button>
                )}
            </Box>

            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1, overflowInline: 'hidden', marginTop: 2}}>
                {renderContents(docs)}
            </Box>

            <Dialog open={renameDialogOpen} onClose={handleRenameClose}>
                <DialogTitle>Rename Subfolder</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label='New Subfolder Name'
                        fullWidth
                        value={rename}
                        onChange={(e) => setRename(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRenameClose}>Cancel</Button>
                    <Button onClick={handleRenameSave} color="primary">Save</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={createFolderDialogOpen} onClose={() => setCreateFolderDialogOpen(false)}>
                <DialogTitle>Create New Folder</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label='Folder Name'
                        fullWidth
                        value={newFolder ?? ''}
                        onChange={(e) => setNewFolder(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCreateFolderDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleCreateFolderSave}>Create</Button>
                </DialogActions>
            </Dialog>
            <CustomSnackbar open={snackbarOpen} message={snackbarMessage}
                            onClose={handleCloseSnackbar}/>

            <ContextMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                onMenuAction={handleMenuAction}
            />
        </GridWrapper>
    );
};

export default FolderView;
