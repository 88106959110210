import React, {useState} from 'react';
import DocumentThumbnail from "../Document/DocumentThumbnail";
import Folder from "./Folder";

const FolderRender = ({
                          docs,
                          customFields,
                          rootFolderId,
                          folderNavigateTo,
                          handleItem,
                          downloadPdf,
                          downloadLoading,
                          handleDeleteOpen,
                          deleteOpen,
                          handleDeleteClose,
                          deleteDocument,
                          deleteLoading,
                          handleCheckboxChange,
                          selectedDocuments,
                          selectedItems,
                          handleRightClick,
                          handleMouseUp,
                          handleMouseEnter,
                          handleMouseDown
                      }) => {
    const [sortOption, setSortOption] = useState('name'); // Sorting option, can be 'name' or 'id'

    const folderList = [];
    const docList = [];

    docs.forEach(item => {
        if (item.folder_id && item.folder_id !== rootFolderId) {
            folderList.push(item);
        }
        if (item.document_ids && ((!item.parent_folder_id && !item.folder_id) || item.folder_id === rootFolderId)) {
            item.document_ids.split(', ').forEach(document_id => {
                if (!docList.some(doc => doc.document_id === document_id)) {
                    docList.push({document_id});
                }
            });
        }
    });

    const sortedFolderList = [...folderList].sort((a, b) => {
        if (sortOption === 'name') {
            return a.folder_name.localeCompare(b.folder_name); // Sort by folder_name alphabetically
        } else {
            return a.folder_id - b.folder_id; // Sort by folder_id
        }
    });

    const sortedDocList = [...docList].sort((a, b) => {
        return a.document_id - b.document_id;
    });

    const allItems = [
        ...sortedFolderList.map((folder) => ({
            folderID: folder.folder_id, documentID: null
        })),
        ...sortedDocList.map((document) => ({
            documentID: document.document_id, folderID: null
        })),
    ];
    return (
        <>
            {/* Sort Dropdown (optional) */}
            {/* <div>
                <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
                    <option value="name">Sort by Name</option>
                    <option value="id">Sort by ID</option>
                </select>
            </div>*/}

            {sortedFolderList.map((folder) => {
                const indexInAllItems = allItems.findIndex(item => item.folderID === folder.folder_id);
                return (
                    <Folder
                        key={`folder-${folder.folder_id}`}
                        folderID={folder.folder_id}
                        folderName={folder.folder_name}
                        folderNavigateTo={folderNavigateTo}
                        selectedItems={selectedItems}
                        handleRightClick={handleRightClick}
                        handleMouseUp={handleMouseUp}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseDown={handleMouseDown}
                        allItems={allItems}
                        index={indexInAllItems}
                    />
                );
            })}

            {sortedDocList.map((doc) => {
                const indexInAllItems = allItems.findIndex(item => item.documentID === doc.document_id);
                return (
                    <DocumentThumbnail
                        key={`document-${doc.document_id}`}
                        documentId={doc.document_id}
                        document={null}
                        customFields={customFields}
                        handleItem={handleItem}
                        downloadPdf={downloadPdf}
                        downloadLoading={downloadLoading}
                        handleDeleteOpen={handleDeleteOpen}
                        deleteOpen={deleteOpen}
                        handleDeleteClose={handleDeleteClose}
                        deleteDocument={deleteDocument}
                        deleteLoading={deleteLoading}
                        handleCheckboxChange={handleCheckboxChange}
                        selectedDocuments={selectedDocuments}
                        selectedItems={selectedItems}
                        handleRightClick={handleRightClick}
                        handleMouseUp={handleMouseUp}
                        handleMouseEnter={handleMouseEnter}
                        handleMouseDown={handleMouseDown}
                        allItems={allItems}
                        index={indexInAllItems}
                    />
                );
            })}

        </>

    );
};

export default FolderRender;
